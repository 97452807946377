import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'layout',
        component: () =>
            import ('@/views/index'),
        redirect: 'homepage',
        children: [{
                path: 'homepage',
                name: 'homepage',
                component: () =>
                    import ('@/views/homepage/homepage'),
                alias: '/',
            }, {
                path: 'municipal',
                name: 'municipal',
                component: () =>
                    import ('@/views/municipal/municipal')
            },
            {
                path: 'hardware',
                name: 'hardware',
                component: () =>
                    import ('@/views/hardware/hardware')
            },
            {
                path: 'software',
                name: 'software',
                component: () =>
                    import ('@/views/software/software')
            },
            {
                path: 'service',
                name: 'service',
                component: () =>
                    import ('@/views/service/service')
            },
            {
                path: 'cooperation',
                name: 'cooperation',
                component: () =>
                    import ('@/views/cooperation/cooperation')
            },
            {
                path: 'poolplan',
                name: 'poolplan',
                component: () =>
                    import ('@/views/poolplan/poolplan')
            },
            {
                path: 'advertisement',
                name: 'advertisement',
                component: () =>
                    import ('@/views/advertisement/advertisement')
            },
            {
                path: 'dynamics',
                name: 'dynamics',
                component: () =>
                    import ('@/views/dynamics/dynamics')
            },
            {
                path: 'companyNews',
                name: 'companyNews',
                component: () =>
                    import ('@/views/companyNews/companyNews'),
            },
            {
                path: 'industryNews',
                name: 'industryNews',
                component: () =>
                    import ('@/views/industryNews/industryNews'),
            },
            {
                path: 'ep',
                name: 'ep',
                component: () =>
                    import ('@/views/ep/ep'),
            },
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes
})

export default router