// 导入自己需要的组件
import { Pagination, Tabs, TabPane } from 'element-ui'
const element = {
    install: function(Vue) {
        Vue.use(Pagination)
        Vue.use(Tabs)
        Vue.use(TabPane)

    }
}
export default element