<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>

<style>
	#app {
		font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #333;
		font-size: 14px;
	}

	html,
	body {
		min-width: 1200px !important;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		text-size-adjust: 100%;
	}

	.el-left {
		float: left;
	}

	.el-right {
		float: right;
	}

	.el-text-center {
		text-align: center;
	}
</style>
