/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
// post请求
function http(url, data = {}, method = 'get') {
    return new Promise((resolve, reject) => { 
        axios({ 
                url: url,
                  
                method: method,
                  
                headers: getHeader(),
                     
                data: data   
            })  
            .then(res => {   
                resolve(res)  
            })  
            .catch(res => {
                reject(res)   
            })
    })
}
/**
 * header
 */

function getHeader() {
    if (window.localStorage.getItem("token")) {
        return {
            'content-type': 'application/json',
            'token': ''
        }
    }
    return {
        'content-type': 'application/json'
    }
}
export default http