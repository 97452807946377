let version = process.env.NODE_ENV;
console.log('envVersion', process.env.NODE_ENV);
var baseUrl = ''

switch (version) {
    //开发版 develop
    case 'development':
        // baseUrl = 'https://testapi.squirrelf.com/php/v1/';
        baseUrl = 'https://www.squirrelf.com/';
        break;
        //体验版 trial
    case 'debug':
        baseUrl = 'https://www.squirrelf.com/';
        // baseUrl = 'https://testapi.squirrelf.com/php/v1/';
        break;
        //正式版 release 
    case 'production':
        baseUrl = 'https://www.squirrelf.com/';
        break;
    default:
        baseUrl = 'https://www.squirrelf.com/';
}
console.log(baseUrl)
const api = {
    /* 公司动态展示 */
    showCompanyNews: baseUrl + 'index/showCompanyNews',

    /* 行业动态展示 */
    showIndustryNews: baseUrl + 'index/showIndustryNews',

    /*环保百科*/
    showEp: baseUrl + 'index/showEp',

    /* 招贤纳士*/
    showJobOffers: baseUrl + 'index/showJobOffers',

    /* 登录 */
    login: baseUrl + 'manage/login',

    /* 新增公司动态 */
    addCompanyNews: baseUrl + 'manage/addCompanyNews',

    /* 删除公司动态 */
    deleteCompanyNewsById: baseUrl + 'manage/deleteCompanyNewsById',

    /* 修改公司动态 */
    updateCompanyNewsById: baseUrl + 'manage/updateCompanyNewsById',

    /* 通过id查询公司动态 */
    selectCompanyNewsById: baseUrl + 'index/selectCompanyNewsById',

    /* 新增行业动态 */
    addIndustryNews: baseUrl + 'manage/addIndustryNews',

    /* 删除行业动态 */
    deleteIndustryNewsById: baseUrl + 'manage/deleteIndustryNewsById',

    /* 修改行业动态 */
    updateIndustryNews: baseUrl + 'manage/updateIndustryNews',

    /* 通过id查询行业动态 */
    selectIndustryNewsById: baseUrl + 'index/selectIndustryNewsById',

    /* 新增环保百科信息 */
    addEp: baseUrl + 'manage/addEp',

    /* 删除环保百科信息 */
    deleteEpById: baseUrl + 'manage/deleteEpById',

    /* 修改环保百科信息 */
    updateEp: baseUrl + 'manage/updateEp',

    /* 通过id查询环保百科信息 */
    selectEpById: baseUrl + 'index/selectEpNewsById',

    /* 新增招聘信息 */
    addRecruitment: baseUrl + 'manage/addRecruitment',

    /* 删除招聘信息 */
    deleteRecruitmentById: baseUrl + 'manage/deleteRecruitmentById',

    /* 修改招聘信息 */
    updateRecruitment: baseUrl + 'manage/updateRecruitment',

    /* 通过id查询招聘信息 */
    selectRecruitmentById: baseUrl + 'index/selectRecruitmentById',
}
export default api