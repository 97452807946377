import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/css/common.css'
import '@/assets/css/style.css'
import element from './element/index'
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
import Api from './utils/api'
import http from './utils/http'
import * as filters from './assets/js/filters.js'
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)


Vue.use(element)
Vue.prototype.$api = Api
Vue.prototype.$http = http
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')